import Academic from "./Academic";

import { Chart,
    BarController, BarElement,
    LineController, LineElement, 
    PointElement, Filler,
    CategoryScale, LinearScale, Title, Tooltip, Legend, } from 'chart.js';

Chart.register(BarController, BarElement,
    LineController, LineElement, 
    PointElement, Filler,
    CategoryScale, LinearScale,  Title, Tooltip, Legend);

export default class Generic {

    static beep(vol, freq, duration){
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var alertbeep  = new AudioContext();
        var v = alertbeep.createOscillator()
        var u = alertbeep.createGain()
        v.connect(u)
        v.frequency.value=freq
        v.type = "square"
        u.connect(alertbeep.destination)
        u.gain.value=vol*0.01
        v.start(alertbeep.currentTime)
        v.stop(alertbeep.currentTime+duration*0.001)
    }

    /**
     * academic related codes
     */
     
    static initCommonPageJS() {
        $("#entryForm").validate({
            errorElement: "em",
            errorPlacement: function (error, element) {
                // Add the `help-block` class to the error element
                error.addClass("help-block");
                error.insertAfter(element);

            },
            highlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
            }
        });

        $(".date_picker").datetimepicker({
            format: "DD/MM/YYYY",
            viewMode: 'days',
            ignoreReadonly: true,
        });
        $(".date_picker_with_clear").datetimepicker({
            format: "DD/MM/YYYY",
            viewMode: 'days',
            showClear: true,
            ignoreReadonly: true
        });


        $(".date_picker2").datetimepicker({
            format: "DD/MM/YYYY",
            viewMode: 'years',
            ignoreReadonly: true
        });

        $(".date_picker3").datetimepicker({
            format: "YYYY-MM-DD",
            viewMode: 'years',
            ignoreReadonly: true
        });

        $(".time_picker").datetimepicker({
            format: 'LT',
            showClear: true,
            ignoreReadonly: true
        });

        $(".date_time_picker").datetimepicker({
            format: "DD/MM/YYYY LT",
            viewMode: 'days',
            ignoreReadonly: true
        });

        $('.date_picker_with_disable_days').datetimepicker({
            format: "DD/MM/YYYY",
            viewMode: 'days',
            ignoreReadonly: true,
            daysOfWeekDisabled: window.disableWeekDays,
            useCurrent: false
        });

        $('.only_year_picker').datetimepicker({
            format: "YYYY",
            viewMode: 'years',
            ignoreReadonly: true,
            useCurrent: false
        });

        // var buttonCommon = {
        //     exportOptions: {
        //         columns: ':not(.notexport)',
        //         format: {
        //             body: function (data, row, column, node) {
        //                 if(typeof(window.changeExportColumnIndex) !== 'undefined') {
        //                     var onValue = typeof window.changeExportColumnValue !== 'undefined' ? window.changeExportColumnValue[0] : 'Active';
        //                     var offValue = typeof window.changeExportColumnValue !== 'undefined' ? window.changeExportColumnValue[1] : 'Inactive';
        //                     if (column === window.changeExportColumnIndex) {
        //                         data = /checked/.test(data) ? onValue : offValue;
        //                     }
        //                 }
        //                 return data;
        //             }
        //         }
        //     }
        // };

        //table with out search
        var table = $('#listDataTable').DataTable({
            pageLength: 25,
            lengthChange: false,
            responsive: true,
            // buttons: [
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'copy',
            //         text: '<i class="fa fa-files-o"></i>',
            //         titleAttr: 'copy',
            //     }),
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'csv',
            //         text: '<i class="fa fa-file-text-o"></i>',
            //         titleAttr: 'csv',
            //     }),
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'excel',
            //         text: '<i class="fa fa-file-excel-o"></i>',
            //         titleAttr: 'Excel',
            //     }),
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'pdf',
            //         text: '<i class="fa fa-file-pdf-o"></i>',
            //         titleAttr: 'pdf',
            //         customize: function (doc) {
            //             doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
            //             doc.content[1].alignment = "center";
            //         }
            //     }),
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'print',
            //         text: '<i class="fa fa-print"></i>',
            //         titleAttr: 'print',
            //     })
            // ]
        });
        // table.buttons().container().appendTo($('.col-sm-6:eq(0)', table.table().container()));

        //table custom
        var table33 = $('#listDataTableOnlyPrint').DataTable({
            lengthChange: false,
            responsive: true,
            paging: false,
            filter: false,
            // buttons: [
            //     $.extend(true, {}, buttonCommon, {
            //         extend: 'print',
            //         text: '<i class="fa fa-print"></i>',
            //         titleAttr: 'print',
            //     })
            // ]
        });
        // table33.buttons().container().appendTo($('.col-sm-6:eq(0)', table33.table().container()));

        var table2 = $('#listDataTableWithSearch').DataTable({
            pageLength: 25,
            // lengthChange: false,
            orderCellsTop: true,
            responsive: true,
            // filter: true,
            select: true,
            // "order": [[0, "desc"]],
            // "scrollY": "380px",
            // "scrollCollapse": true,
            paging: true,
            processing: false, // Switch to client-side processing
            serverSide: false, // No longer server-side
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            language: {
                search: "Filter Results:",
                lengthMenu: "Show _MENU_ entries"
            }
        });
        $('select[name="listDataTableWithSearch_length"]').css({"margin-left":"0.5rem","margin-right":"0.5rem"})
        
        var table4 = $('#listReportTableWithSearch').DataTable({
            pageLength: 25,
            // lengthChange: false,
            // orderCellsTop: true,
            responsive: true,
            ordering: false,
            // filter: true,
            select: true,
            // "order": [[0, "desc"]],
            // "scrollY": "380px",
            // "scrollCollapse": true,
            paging: true,
            processing: false, // Switch to client-side processing
            serverSide: false, // No longer server-side
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            language: {
                search: "Filter Results:",
                lengthMenu: "Show _MENU_ entries"
            }
        });
        $('select[name="listReportTableWithSearch_length"]').css({"margin-left":"0.5rem","margin-right":"0.5rem"})


        let stopchange = false;
        $('html .statusChangeHolder, html #listDataTableWithSearch, html #listDataTable, html #listStudents')
            .on('change', 'input.statusChange', function (e) {
            let that = $(this);
            if (stopchange === false) {
                let isActive = $(this).prop('checked') ? 1 : 0;
                let pk = $(this).attr('data-pk');
                let field = $(this).prop('name')? $(this).attr('name') : 'status';
                let newpostUrl = postUrl.replace(/\.?0+$/, pk);
                var postData = {};
                postData[field] = isActive;
                axios.post(newpostUrl, postData)
                    .then((response) => {
                        if (response.data.success) {
                            toastr.success(response.data.message);
                        }
                        else {
                            let status = response.data.message;
                            if (stopchange === false) {
                                stopchange = true;
                                that.bootstrapToggle('toggle');
                                stopchange = false;
                            }
                            toastr.error(status);
                        }
                    }).catch((error) => {
                        // console.log(error.response);
                        let status = error.response.statusText;
                        if (stopchange === false) {
                            stopchange = true;
                            that.bootstrapToggle('toggle');
                            stopchange = false;
                        }
                        toastr.error(status);

                    });
            }
        });
        $('html #watchStatusForm')
            .on('change', 'input.statusChange', function (e) {
            let that = $(this);
            if (stopchange === false) {
                let isActive = $(this).prop('checked') ? 1 : 0;
                let pk = $(this).attr('data-pk');
                let field = $(this).prop('name')? $(this).attr('name') : 'watch';
                let newpostUrl = postUrl.replace(/\.?0+$/, pk);
                var postData = {};
                postData[field] = isActive;
                axios.post(newpostUrl, postData)
                    .then((response) => {
                        if (response.data.success) {
                            toastr.success(response.data.message);
                        }
                        else {
                            let status = response.data.message;
                            if (stopchange === false) {
                                stopchange = true;
                                that.bootstrapToggle('toggle');
                                stopchange = false;
                            }
                            toastr.error(status);
                        }
                    }).catch((error) => {
                        // console.log(error.response);
                        let status = error.response.statusText;
                        if (stopchange === false) {
                            stopchange = true;
                            that.bootstrapToggle('toggle');
                            stopchange = false;
                        }
                        toastr.error(status);

                    });
            }
        });

        $('html #listWelfare')
            .on('change', 'input.statusChange', function (e) {
            let that = $(this);
            if (stopchange === false) {
                let isActive = $(this).prop('checked') ? 1 : 0;
                let pk = $(this).attr('data-pk');
                let field = 'welfare';
                let newpostUrl = welfareUrl.replace(/\.?0+$/, pk);
                var postData = {};
                postData[field] = isActive;
                axios.post(newpostUrl, postData)
                    .then((response) => {
                        if (response.data.success) {
                            toastr.success(response.data.message);
                        }
                        else {
                            let status = response.data.message;
                            if (stopchange === false) {
                                stopchange = true;
                                that.bootstrapToggle('toggle');
                                stopchange = false;
                            }
                            toastr.error(status);
                        }
                    }).catch((error) => {
                        // console.log(error.response);
                        let status = error.response.statusText;
                        if (stopchange === false) {
                            stopchange = true;
                            that.bootstrapToggle('toggle');
                            stopchange = false;
                        }
                        toastr.error(status);

                    });
            }
        });

        $('html #listInvestigate')
            .on('change', 'input.statusChange', function (e) {
            let that = $(this);
            if (stopchange === false) {
                let isActive = $(this).prop('checked') ? 1 : 0;
                let pk = $(this).attr('data-pk');
                let field = 'status';
                let newpostUrl = investUrl.replace(/\.?0+$/, pk);
                var postData = {};
                postData[field] = isActive;
                axios.post(newpostUrl, postData)
                    .then((response) => {
                        if (response.data.success) {
                            toastr.success(response.data.message);
                        }
                        else {
                            let status = response.data.message;
                            if (stopchange === false) {
                                stopchange = true;
                                that.bootstrapToggle('toggle');
                                stopchange = false;
                            }
                            toastr.error(status);
                        }
                    }).catch((error) => {
                        // console.log(error.response);
                        let status = error.response.statusText;
                        if (stopchange === false) {
                            stopchange = true;
                            that.bootstrapToggle('toggle');
                            stopchange = false;
                        }
                        toastr.error(status);

                    });
            }
        });

        $(".year_picker").datetimepicker({
            format: "YYYY",
            viewMode: 'years',
            ignoreReadonly: true
        });

        $('input').not('.dont-style').iCheck({
            checkboxClass: 'icheckbox_square-blue',
            radioClass: 'iradio_square-blue',
            increaseArea: '20%' /* optional */
        });
        $('.select2').select2();


    }

    static initDeleteDialog() {
        $('html #listDataTableWithSearch, html #listDataTable, html #listDataTableOnlyPrint, html #listReportTableWithSearch, html #listStudents').on('submit', 'form.myAction', function (e) {
            e.preventDefault();
            var that = this;
            swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this record!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.value) {
                    that.submit();
                }
            });
        });
    }
    
    static smsFormInit() {
        $('#recipient_change').on('change', function () {
            let recipient = $(this).val();
            if (recipient == 'others') {
                $(".recipient-other").show();
            } else {
                $(".recipient-other").hide();
            }
        });
    }


    
    static initChart(chartId, chartType, data, options) {
        var chartElement = document.getElementById(chartId);
        if(chartElement) {
            var config = {
                type: chartType,
                data: data,
                options: options
            };
            var ctx = chartElement.getContext('2d');
            return new Chart(ctx, config);
        }
        return null;
    }

    static initRadioDisplayToggle() {
        $("div.upload-file-radio").show();
        $("div.radio.radio-display-toggle label").click(function() {
            var $this = $(this);
            $(".radio-toggle-hidden").hide();
            $this.parents('div.radio').find('.radio-toggle-hidden').show();
        });
    }

    static initMarksheetPublic() {
        Generic.initCommonPageJS();
        // $('#class_change').val('').trigger('change');
        $('#class_change').on('change', function () {
            let class_id = $(this).val();
            if(class_id) {
                //get sections
                Academic.getExam(class_id);
            }
            else{
                $('select[name="exam_id"]').empty().select2({placeholder: 'Pick a exam...'});
            }

        });
    }
    static loaderStart(){
        // console.log('loader started...');
        $('.ajax-loader').css('display','block');
    } 
    static loaderStop(){
        // console.log('loader stoped...');
        $('.ajax-loader').css('display','none');
    }
    
    
    /**
     * Displays overlay with "Please wait" text. Based on bootstrap modal. Contains animated progress bar.
     */
    static showPleaseWait() {
        
        if (document.querySelector("#pleaseWaitDialog") == null) {
            var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog">\
                <div class="modal-dialog">\
                    <div class="modal-content">\
                        <div class="modal-header">\
                            <h4 class="modal-title">Please wait...</h4>\
                        </div>\
                        <div class="modal-body">\
                            <div class="progress">\
                              <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"\
                              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%; height: 40px">\
                              </div>\
                            </div>\
                        </div>\
                    </div>\
                </div>\
            </div>';
            $(document.body).append(modalLoading);
        }
      
        $("#pleaseWaitDialog").modal("show");
    }
    
    /**
     * Hides "Please wait" overlay. See function showPleaseWait().
     */
    static hidePleaseWait() {
        $("#pleaseWaitDialog").modal("hide");
    }
    
    static showHidePleaseWaitAfter(seconds, refresh) {
        Generic.showPleaseWait();
        setTimeout(function(){
            if(refresh) {
                window.location.reload(true);
            }
        }, seconds * 1000);
    }
}